<script lang="ts">
export const countriesArray = [
	{
		code: 'pkr',
		name: 'PKR',
		flag: 'https://hatscripts.github.io/circle-flags/flags/pk.svg',
	},
	{
		code: 'usd',
		name: 'USD',
		flag: 'https://hatscripts.github.io/circle-flags/flags/us.svg',
	},
	{
		code: 'cad',
		name: 'CAD',
		flag: 'https://hatscripts.github.io/circle-flags/flags/ca.svg',
	},
	{
		code: 'gbp',
		name: 'GBP',
		flag: 'https://hatscripts.github.io/circle-flags/flags/gb.svg',
	},
	{
		code: 'aed',
		name: 'AED',
		flag: 'https://hatscripts.github.io/circle-flags/flags/ae.svg',
	},
	{
		code: 'sar',
		name: 'SAR',
		flag: 'https://hatscripts.github.io/circle-flags/flags/sa.svg',
	},
	{
		code: 'eur',
		name: 'EUR',
		flag: 'https://hatscripts.github.io/circle-flags/flags/eu.svg',
	},
	{
		code: 'aud',
		name: 'AUD',
		flag: 'https://hatscripts.github.io/circle-flags/flags/au.svg',
	},
	{
		code: 'inr',
		name: 'INR',
		flag: 'https://hatscripts.github.io/circle-flags/flags/in.svg',
	},
];
</script>
<script setup lang="ts">
import {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
	Button,
	FormField,
	Sheet,
	SheetContent,
	SheetTrigger,
	SingleSelectList,
	SingleSelectListItem,
	SheetTitle,
	SheetHeader,
	FormControl,
	FormItem,
} from '@laam/ui/base';
import { toTypedSchema } from '@vee-validate/zod';
import { z } from 'zod';
import { useForm } from 'vee-validate';
import { createReusableTemplate } from '@vueuse/core';

interface CurrencySelectorProps {
	type: 'dropdown' | 'sheet';
}

withDefaults(defineProps<CurrencySelectorProps>(), {
	type: 'dropdown',
});

const { setCurrency, selectedCurrency } = useCurrency();

const schema = toTypedSchema(
	z.object({
		currency: z.string(),
	}),
);

const { values } = useForm({
	validationSchema: schema,
	initialValues: {
		currency: selectedCurrency,
	},
	keepValuesOnUnmount: true,
});

watch(values, (value) => {
	if (value.currency) {
		const country = value.currency as unknown;
		setCurrency(country as CURRENCY);
	}
});

interface DefaultTriggerProps {
	modelValue: string;
}

interface DefaultContentProps {
	modelValue: string;
	handleChange: (e: Event | unknown, shouldValidate?: boolean) => void;
}

const [DefineDefaultTrigger, DefaultTrigger] =
	createReusableTemplate<DefaultTriggerProps>();

const [DefineDefaultContent, DefaultContent] =
	createReusableTemplate<DefaultContentProps>();

const getSelectedCountry = (code: string) => {
	return countriesArray.find((country) => country.code === code);
};
</script>
<template>
	<DefineDefaultTrigger v-slot="{ modelValue }">
		<Button
			class="w-full border-none hover:cursor-pointer"
			variant="dropdown"
			size="dropdown"
			:as-child="true"
			data-testid="currency-selector-button"
		>
			<div class="w-full">
				<div class="flex items-center flex-1 w-full gap-md">
					<div class="w-5xl h-5xl !max-w-[20px] min-w-[20px] lg:hidden">
						<NuxtImg
							:src="getSelectedCountry(modelValue)!.flag"
							alt="country flag"
							loading="eager"
							width="10"
							class="w-full"
						>
						</NuxtImg>
					</div>
					<span
						class="lg:!text-md font-medium !text-xxs lg:text-gray-800 hidden lg:block ml-xxs"
					>
						Currency
					</span>
				</div>
			</div>
		</Button>
	</DefineDefaultTrigger>

	<DefineDefaultContent v-slot="{ modelValue, handleChange }">
		<div id="listContainer" @input.prevent>
			<SingleSelectList
				:model-value="modelValue"
				@update:model-value="(value: string) => handleChange(value)"
			>
				<SingleSelectListItem
					v-for="(item, index) in countriesArray"
					:key="index"
					:value="item.code"
					show-check
					class="text-sm font-medium text-left text-gray-900 !p-md"
					data-testid="currency-selector-dropdown-item"
					@input.prevent
				>
					<div v-if="item && item.name" class="flex gap-xl">
						<NuxtImg
							:src="item.flag"
							alt="country flag"
							loading="eager"
							width="10"
							class="w-5xl h-5xl"
						/>
						<span class="text-left grow">
							{{ item.name }}
						</span>
					</div>
				</SingleSelectListItem>
			</SingleSelectList>
		</div>
	</DefineDefaultContent>
	<FormField
		v-slot="{ handleChange, componentField }"
		name="currency"
		class="w-full"
	>
		<FormItem class="w-full">
			<FormControl>
				<template v-if="type === 'dropdown'">
					<DropdownMenu class="bg-white">
						<DropdownMenuTrigger as-child class="w-full hover:bg-gray-50">
							<slot
								name="default"
								:model-value="componentField.modelValue"
								:methods="{ getSelectedCountry }"
							>
								<DefaultTrigger :model-value="componentField.modelValue" />
							</slot>
						</DropdownMenuTrigger>
						<DropdownMenuContent class="w-full bg-white">
							<DefaultContent
								:model-value="componentField.modelValue"
								:handle-change="handleChange"
							/>
						</DropdownMenuContent>
					</DropdownMenu>
				</template>
				<template v-if="type === 'sheet'">
					<Sheet @update-open="(open: boolean) => console.log('open', open)">
						<SheetTrigger as-child class="w-full hover:bg-gray-50">
							<slot
								name="default"
								:model-value="componentField.modelValue"
								:methods="{ getSelectedCountry }"
							>
								<DefaultTrigger :model-value="componentField.modelValue" />
							</slot>
						</SheetTrigger>
						<SheetContent side="bottom" class="w-full bg-white">
							<SheetHeader>
								<SheetTitle>Currency</SheetTitle>
							</SheetHeader>
							<DefaultContent
								:model-value="componentField.modelValue"
								:handle-change="handleChange"
								class="px-3xl pt-3xl"
							/>
						</SheetContent>
					</Sheet>
				</template>
			</FormControl>
		</FormItem>
	</FormField>
</template>
